//vendor
require("@fancyapps/fancybox");
import LazyLoad from "vanilla-lazyload";

//Polyfills
import 'svgxuse/svgxuse.js';
import { ajax } from 'jquery';

export default class APP {
	constructor() {

		//Handlers
		$(document).on('click', '.btn--toggle-nav', this.navToggle);
		$(document).on('click', '.accordion__toggle', this.toggleAccordion);
		$(document).on('click', '.type-menu__btn', this.toggleTypeMenu);

		$(document).on('click', function(e) {
			$('.type-menu').removeClass('is-open');
		});

		//Load future timeline posts
		$(document).on('click', '.load-future-posts', function() {

			var $this = $(this);
			$this.addClass('btn--loading');
			var $tax = ($this.data('tax-id') ? $this.data('tax-id') : false);

			$.ajax({
				type: 'POST',
				url: wpajax.url,
				dataType: "html", // add data type
				data: { 'action' : 'get_ajax_timeline_future_posts', 'tax': $tax },
				success: function( response ) {
					setTimeout(function() {
						$this.removeClass('btn--loading');
						$('.load-future-posts-cnt').hide();
						$( '#timeline' ).prepend( response ); 

						//update Lazyload
						lazyLoadInstance.update();

					}, 750);
				},
				error: function() {
					console.log('error');
					$this.removeClass('btn--loading');
					$('.load-future-posts-cnt').hide();
				},
			});
		});

		$(window).scroll(function() {
			if($(window).scrollTop() > ($(window).height() * .75)) {
			$('.call-to-action').addClass('inactive');
			} else {
			$('.call-to-action').removeClass('inactive');
			}
		}).trigger('scroll');


		this.default();

	}

	default() {

		//Unorphanize
		var textElements = document.querySelectorAll( 'p:not(.excluded)' );
		for ( var i = 0; i < textElements.length; i++ ) {
			var textElement = textElements[ i ];
			textElement.innerHTML = textElement.innerHTML.replace(/\s([^\s<]+)\s*$/,'&nbsp;$1');
		}


		// Add smooth scrolling to all links
		$("a.scrollto").on('click', function(event) {

			if (this.hash !== "") {
				event.preventDefault();
		
				var hash = this.hash;
		
				$('html, body').animate({
				scrollTop: $(hash).offset().top
				}, 1000, function() {
		
				//window.location.hash = hash;
				});
			}
		});

	}

	navToggle(e) {

		e.preventDefault();

		var $_nav = $('#mainnav'),
			nav_is_open = $_nav.hasClass('is-open');

		if (nav_is_open) {
			$_nav.removeClass('is-open');
			$('body').removeClass('nav-is-open');
			app.lock_scroll(false);
		} else {
			$_nav.addClass('is-open');
			$('body').addClass('nav-is-open');
			app.lock_scroll(true);
		}

	}

	toggleTypeMenu(e) {

		e.preventDefault();
		e.stopPropagation();

		var $_typemenu = $('.type-menu'),
			typemenu = $_typemenu.hasClass('is-open');

		if (typemenu) {
			$_typemenu.removeClass('is-open');
		} else {
			$_typemenu.addClass('is-open');
		}

	}

	toggleAccordion(e) {

		e.preventDefault();

		var $_toggle = $(this),
			$_question = $_toggle.parents('.accordion'),
			question_is_opened = $_question.hasClass('active');

		if(question_is_opened) {
			$_question.removeClass('active');
		} else {
			$_question.addClass('active');
		}

	}

	lock_scroll(state) {
		if (state == true) {
			$('body, html').width($('body').width())
				.height($('body').height())
				.css('overflow', 'hidden');

		} else {
			$('body, html').removeAttr('style');
			$('#content').removeAttr('style');
		}
	}

};

var app = new APP();



//Init Lazyload
var lazyLoadInstance = new LazyLoad({
	elements_selector: ".lazy"
});